import React from 'react'

export const LIMITED_MAX_WIDTH = 1140
const LimitedMaxWidthContainer: React.FC = ({children}) => (
  <div
    style={{
      maxWidth: LIMITED_MAX_WIDTH,
      marginRight: 'auto',
      marginLeft: 'auto',
    }}
  >
    {children}
  </div>
)

export default LimitedMaxWidthContainer
