import {OptionsType, ValueType} from 'react-select'
import {FiltersOptionType} from './types'

export const arrayWrapSelection = <
  T extends FiltersOptionType | OptionsType<FiltersOptionType>
>(
  selection: ValueType<T>,
): readonly T[] => {
  if (selection === null || selection === undefined) return []
  if ('length' in selection) return selection as readonly T[]
  return [selection]
}
