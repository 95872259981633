import React, {FormEventHandler, useState} from 'react'

import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'

import Button from 'react-bootstrap/Button'

import {RootState} from '../store/types'
import {StoreDispatch} from '../store'
import {
  loginUser,
  loginUserWithPassword,
  signUpUserWithPassword,
} from '../actions/thunks'

import Layout from './Layout'
import {Alert, Col, Modal, Row} from 'react-bootstrap'
import {EmailPasswordInput} from './EmailPasswordInput'

interface OwnProps {
  type: 'login' | 'signup'
}

interface Props extends OwnProps {
  isAuthenticated: boolean
  isVerifying: boolean
  sendMagicLink: (email: string) => void
  signUpWithPassword: (args: {email: string; password: string}) => void
  loginUserWithPassword: (args: {email: string; password: string}) => void
  errorMessage: string | null
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerifying: state.auth.isVerifying,
  errorMessage:
    state.auth.signUpErrorMessage || state.auth.loginWithPasswordErrorMessage,
  ...ownProps,
})

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
  sendMagicLink: (email: string) => dispatch(loginUser(email)),
  signUpWithPassword: (args: {email: string; password: string}) =>
    dispatch(signUpUserWithPassword(args)),
  loginUserWithPassword: (args: {email: string; password: string}) =>
    dispatch(loginUserWithPassword(args)),
})

function LoginSignUpForm({
  isVerifying,
  isAuthenticated,
  sendMagicLink,
  type,
  signUpWithPassword,
  loginUserWithPassword,
  errorMessage,
}: Props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isUsingPassword, setIsUsingPassword] = useState(false)

  const submit: FormEventHandler = event => {
    event.preventDefault()
    if (isUsingPassword) {
      type === 'login'
        ? loginUserWithPassword({email, password})
        : signUpWithPassword({email, password})
      return
    }
    sendMagicLink(email)
  }

  const title = type === 'login' ? 'Log in' : 'Sign up'

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <Layout>
      <Alert variant="danger" show={Boolean(errorMessage)} transition>
        {errorMessage}
      </Alert>
      {!errorMessage && <br />}
      {isVerifying ? (
        'Verifying&hellip;'
      ) : (
        <form onSubmit={submit}>
          <Modal.Dialog size="lg">
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EmailPasswordInput
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                showPasswordInput={isUsingPassword}
              />
              {isUsingPassword && type === 'login' && (
                <Link
                  to="/reset-password"
                  style={{fontSize: '14px'}}
                  className="text-muted"
                >
                  Forgot password?
                </Link>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Row className="w-100">
                {!isUsingPassword && (
                  <>
                    <Col className="w-100">
                      <Button
                        type="submit"
                        variant="primary"
                        onClick={submit}
                        disabled={!email}
                        className="w-100"
                      >
                        Send magic link
                      </Button>
                    </Col>
                    <Col
                      className="d-flex justify-content-center align-items-center font-weight-bold"
                      style={{flexGrow: 0, fontSize: '14px'}}
                    >
                      OR
                    </Col>
                  </>
                )}

                <Col>
                  <Button
                    type="submit"
                    disabled={isUsingPassword && (!password || !email)}
                    onClick={
                      isUsingPassword ? submit : () => setIsUsingPassword(true)
                    }
                    className="w-100"
                  >
                    {isUsingPassword ? title : `${title} with password`}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal.Dialog>
        </form>
      )}
    </Layout>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginSignUpForm)
