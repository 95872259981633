import React, {
  useMemo,
  forwardRef,
  useState,
  useEffect,
  ForwardedRef,
  MutableRefObject,
  useRef,
} from 'react'
import {connect} from 'react-redux'
import classnames from 'classnames'

import CreatableSelect from 'react-select/creatable'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons'

import {actions} from '../../actions'
import {StoreDispatch} from '../../store'
import * as types from '../../store/types'
import * as selectors from '../../selectors'

import {FiltersOptionType, TextSearchMode} from './types'
import {MultiValueLabel} from './MultiValueLabel'
import {SelectContainer} from './SelectContainer'
import {DropdownIndicator} from './DropdownIndicator'
import {Placeholder} from './Placeholder'
import {Control} from './Control'
import {Overlay, Tooltip} from 'react-bootstrap'
import {ValueContainer} from './ValueContainer'
import {arrayWrapSelection} from './utils'
import {useIsSmallScreen} from '../hooks'

export type SearchComponentType = CreatableSelect<FiltersOptionType>

const mapStateToProps = (
  state: types.RootState,
  ownProps: {listId: types.ListIdentifier},
) => ({
  currentFilters: state.listViews[ownProps.listId].filters,
  filterOptions: selectors.getFilterOptions(state, ownProps.listId),
  visibleSubtypes: selectors.getVisibleItemSubtypeOptions(
    state,
    ownProps.listId,
  ),
  visibleItems: selectors.getVisibleItems(state, ownProps.listId),
  allItemNames: selectors.allItemNames(state, ownProps.listId),
  selectedItemId: state.selectedItemId,
})

const mapDispatchToProps = (
  dispatch: StoreDispatch,
  ownProps: {listId: types.ListIdentifier},
) => ({
  updateFilters: (filterState: Partial<types.ItemFilterState>) =>
    dispatch(actions.updateFilterState(filterState, ownProps.listId)),
})

interface FiltersProps {
  className?: string
  listId: types.ListIdentifier
  currentFilters: types.ItemFilterState
  filterOptions: types.SaddlebagFilterOptions
  visibleSubtypes: string[]
  updateFilters: ReturnType<typeof mapDispatchToProps>['updateFilters']
  visibleItems: types.GriffonItem[]
  allItemNames: {id: string; name: string}[]
  navigateToItem: null | ((item: types.GriffonItem | string) => void)
  selectedItemId: string | null
}

interface ChoiceLabel {
  label: string
}

const attunementChoices: Array<types.ISearchTag<'requiresAttunement', boolean> &
  ChoiceLabel> = [
  {value: true, label: 'Requires attunement', type: 'requiresAttunement'},
  {value: false, label: 'No attunement', type: 'requiresAttunement'},
]

const freeChoices: Array<types.ISearchTag<'isFree', boolean> & ChoiceLabel> = [
  {value: true, label: 'Available for free', type: 'isFree'},
  {value: false, label: 'Patrons only', type: 'isFree'},
]

const rarityChoices: Array<types.RaritySearchTag & ChoiceLabel> = ([
  'common',
  'uncommon',
  'rare',
  'very rare',
  'legendary',
  'artifact',
  '__multiple_rarities__',
] as const).map(value => ({
  type: 'rarity',
  value,
  label:
    value === '__multiple_rarities__'
      ? 'Multiple rarities'
      : value.replace(/^./, l => l.toUpperCase()),
}))

const significanceChoices: Array<types.ISearchTag<'significance', string> &
  ChoiceLabel> = ['major', 'minor'].map(value => ({
  type: 'significance',
  value,
  label: value.replace(/^./, l => l.toUpperCase()),
}))

const cardVolumeChoices: Array<types.ISearchTag<'cardVolume', number> &
  ChoiceLabel> = [1, 2, 3, 4, 5, 6, 7].map(volume => ({
  type: 'cardVolume',
  label: `Card Volume ${volume} / Deck ${volume} / Pack ${volume}`,
  value: volume,
}))

const bookVolumeChoices: Array<types.ISearchTag<'bookVolume', number> &
  ChoiceLabel> = [1, 2, 3].map(volume => ({
  type: 'bookVolume',
  label: `Book ${volume}`,
  value: volume,
}))

const textSearchModeLabel = (mode: TextSearchMode): string =>
  ({
    'text:nameAndMeta': 'Name/Meta',
    'text:description': 'Description',
    'text:flavour': 'Flavor Text',
  }[mode])

const _Filters = (
  {
    currentFilters,
    filterOptions,
    updateFilters,
    allItemNames,
    visibleItems,
    navigateToItem,
    className,
    selectedItemId,
  }: FiltersProps,
  ref: ForwardedRef<SearchComponentType>,
) => {
  const inputRef = ref as MutableRefObject<SearchComponentType>
  const isSmallScreen = useIsSmallScreen()
  const [isFocused, setIsFocused] = useState(false)
  const itemTypeChoices: Array<types.ISearchTag<'itemType'> &
    ChoiceLabel> = filterOptions.itemType.map(t => ({
    value: t,
    label: t,
    type: 'itemType',
  }))
  const nameChoices: Array<types.ISearchTag<'name'> & ChoiceLabel> = useMemo(
    () =>
      allItemNames.map(i => ({
        value: i.id,
        label: i.name,
        type: 'name',
      })),
    [allItemNames],
  )
  const [textSearchMode, setTextSearchMode] = useState<TextSearchMode>(
    'text:nameAndMeta',
  )

  const groupedOptions: Array<{label: string; options: FiltersOptionType[]}> = [
    {label: 'Item type', options: itemTypeChoices},
    {label: 'Attunement', options: attunementChoices},
    {label: 'Rarity', options: rarityChoices},
    {label: 'Subrarity', options: significanceChoices},
    {label: 'Available for free', options: freeChoices},
    {label: 'Card volume', options: cardVolumeChoices},
    {label: 'Book', options: bookVolumeChoices},
  ]

  const hasFilters =
    currentFilters.attunementType.length ||
    currentFilters.itemType.length ||
    currentFilters.itemSubtype.length ||
    currentFilters.tagSearch.length
  if (navigateToItem !== null && !hasFilters) {
    groupedOptions.push({label: 'Jump to', options: nameChoices})
  }

  const escapeHandler = (event: globalThis.KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      if (isFocused) {
        inputRef?.current?.blur()
      } else if (!selectedItemId) {
        updateFilters({
          tagSearch: [],
        })
      }
    }
    if (event.key === 'Backspace' && !isFocused) {
      inputRef?.current?.focus()
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', escapeHandler)

    return function cleanup() {
      document.removeEventListener('keyup', escapeHandler)
    }
  })

  // ISO string
  const [lastDismissedAt, setLastDismissedAt] = useState<string | null>(
    window.localStorage.getItem('dismissedSearchModeTooltipAt'),
  )

  const dismissTooltip = () => {
    const now = new Date().toISOString()
    setLastDismissedAt(now)
    window.localStorage.setItem('dismissedSearchModeTooltipAt', now)
  }

  const hasDismissedToday =
    new Date().toISOString().slice(0, 10) === lastDismissedAt?.slice(0, 10)

  const nextTextSearchMode =
    textSearchMode === 'text:nameAndMeta'
      ? 'text:description'
      : textSearchMode === 'text:description'
      ? 'text:flavour'
      : 'text:nameAndMeta'

  return (
    <>
      <CreatableSelect<FiltersOptionType>
        textSearchMode={textSearchMode}
        setTextSearchMode={setTextSearchMode}
        closeMenuOnSelect={false}
        blurInputOnSelect={false}
        ref={ref}
        styles={{
          menu: provided => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            zIndex: 1001, // just above OverlayTrigger (thumbnail/attunement tooltips)
          }),
          container: provided => {
            return {
              ...provided,
              width: isSmallScreen ? '300px' : '100%',
              maxWidth: '95vw',
            }
          },
          control: provided => {
            return {
              ...provided,
              ':hover': {
                borderColor: 'transparent',
              },
              borderColor: 'transparent',
              boxShadow: 'none',
              zIndex: 1002, // above menu's shadow
            }
          },
          placeholder: provided => {
            return {...provided, fontStyle: 'italic'}
          },
        }}
        isMulti
        placeholder={`${visibleItems.length} items`}
        name="text"
        components={{
          MultiValueLabel,
          SelectContainer,
          DropdownIndicator,
          Placeholder,
          Control,
          ValueContainer,
        }}
        tabSelectsValue={false}
        options={groupedOptions}
        formatOptionLabel={option => {
          // Display a shorter label, while keeping the original,
          // searchable label for the dropdown suggestion list
          if (option.type === 'cardVolume') {
            return `Vol. ${option.value}`
          }

          return option.label
        }}
        className={classnames(
          'basic-multi-select',
          'ledger-filters',
          className,
        )}
        classNamePrefix="select"
        isValidNewOption={input => !!input}
        createOptionPosition="first"
        filterOption={(option, rawInput) => {
          const lowerInput = rawInput.toLowerCase()
          const optionData: FiltersOptionType | {__isNew__: true} = option.data

          if ('__isNew__' in optionData) return true

          const type = optionData.type
          const lowerLabel = optionData.label.toLowerCase()
          const lowerValue = `${optionData.value}`.toLowerCase()

          if (
            optionData.type === 'rarity' &&
            optionData.value === '__multiple_rarities__'
          )
            return 'multiple rarities / rarity varies'.includes(lowerInput)
          if (type === 'requiresAttunement')
            return 'attunement'.includes(lowerInput)
          if (type === 'isFree') return 'free / patron'.includes(lowerInput)
          if (type === 'name')
            return (
              rawInput.length > 0 &&
              navigateToItem !== null &&
              !hasFilters &&
              lowerLabel.includes(lowerInput)
            )

          return (
            lowerLabel.includes(lowerInput) || lowerValue.includes(lowerInput)
          )
        }}
        formatCreateLabel={input => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <em>
              Search &ldquo;{input}&rdquo; in{' '}
              {textSearchModeLabel(textSearchMode)}
            </em>

            {/* show on desktop only */}
            <TeachingTabIcon
              showTooltip={!hasDismissedToday}
              label={`Press TAB to search by ${textSearchModeLabel(
                nextTextSearchMode,
              )}`}
            />
          </div>
        )}
        value={currentFilters.tagSearch.map(t => ({
          ...t,
          label:
            t.type === 'requiresAttunement'
              ? t.value
                ? 'Requires attunement'
                : 'No attunement'
              : t.type === 'isFree'
              ? t.value
                ? 'Available for free'
                : 'Patrons only'
              : t.value.toString(),
        }))}
        onChange={selection => {
          dismissTooltip()
          const selectionList = arrayWrapSelection(selection)
          const firstSelection = selectionList[0]
          if (
            selectionList.length === 1 &&
            firstSelection &&
            firstSelection.type === 'name'
          ) {
            navigateToItem?.(firstSelection.value)
            return
          }

          const selectionListWithTextSearchModeApplied: types.SearchTag[] = selectionList.map(
            (s): types.SearchTag => {
              if (s.type !== undefined) return s

              return {
                ...s,
                type: textSearchMode,
              }
            },
          )

          updateFilters({
            tagSearch: selectionListWithTextSearchModeApplied,
          })
        }}
        onKeyDown={event => {
          if (event.key === 'Tab') {
            event.preventDefault()
            dismissTooltip()
            setTextSearchMode(nextTextSearchMode)
            return false
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false)
          // event.preventDefault()
        }}
      />
    </>
  )
}

function TeachingTabIcon({
  showTooltip,
  label,
}: {
  showTooltip: boolean
  label: string
}) {
  const tabIconRef = useRef<HTMLSpanElement>(null)

  return (
    <>
      <span
        ref={tabIconRef}
        className="d-none d-lg-inline"
        style={{
          fontSize: 'smaller',
          border: '1px solid rgba(0, 0, 0, 0.5)',
          padding: '0.25rem',
          borderRadius: 4,
          backgroundColor: 'rgba(100, 100, 100, 0.1)',
        }}
      >
        Tab <FontAwesomeIcon icon={faLongArrowAltRight} />
      </span>
      {tabIconRef.current && (
        <Overlay
          show={showTooltip}
          target={tabIconRef.current}
          placement="right"
        >
          <Tooltip id="search-mode-tooltip">{label}</Tooltip>
        </Overlay>
      )}
    </>
  )
}

const Filters = forwardRef(_Filters)

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Filters)
