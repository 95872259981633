import React from 'react'
import classnames from 'classnames'

import {components as selectComponents} from 'react-select'
import {SelectComponents} from 'react-select/src/components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

import {FiltersOptionType} from './types'
import {useIsSmallScreen} from '../hooks'

export function PlaceholderText({children}: {children: React.ReactNode}) {
  return (
    <>
      <span className="d-lg-none">Search and filter </span>
      <span className="d-none d-lg-inline">
        Start typing to search or filter{' '}
      </span>
      {children}&hellip;
    </>
  )
}
export const Placeholder: SelectComponents<
  FiltersOptionType
>['Placeholder'] = ({className, children, ...props}) => {
  className = classnames(className, 'font-italic')
  const isSmallScreen = useIsSmallScreen()
  if (!isSmallScreen) {
    return null
  }
  return (
    <div>
      <FontAwesomeIcon
        icon={faSearch}
        fixedWidth
        className="text-primary d-none d-lg-inline-block"
      />
      <selectComponents.Placeholder {...props} className={className}>
        <PlaceholderText>{children}</PlaceholderText>
      </selectComponents.Placeholder>
    </div>
  )
}
