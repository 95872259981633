import React, {useEffect} from 'react'
import classnames from 'classnames'

import {components as selectComponents} from 'react-select'
import {SelectComponents} from 'react-select/src/components'

import {FiltersOptionType} from './types'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../store'
import {useIsSmallScreen, useScreenSize} from '../hooks'
import {LIMITED_MAX_WIDTH} from '../LimitedMaxWidthContainer'
import {arrayWrapSelection} from './utils'
import {MultiValueLabel} from './MultiValueLabel'
import {actions} from '../../actions'
const STICKY_FILTERS_MIN_WIDTH = 1420

export const SelectContainer: SelectComponents<
  FiltersOptionType
>['SelectContainer'] = ({className, ...props}) => {
  className = classnames(className, {
    'ledger-filters-menu-showing': props.selectProps.menuIsOpen,
  })
  const navCenterWidth = useSelector(
    (state: RootState) => state.ui.navCenterWidth,
  )
  const navScrollPercent = useSelector(
    (state: RootState) => state.ui.navScrollPercent,
  )
  const dispatch = useDispatch()
  const {width: screenWidth} = useScreenSize()

  const isSmallScreen = useIsSmallScreen()

  useEffect(() => {
    if (isSmallScreen) {
      dispatch(actions.uiSetHideNavButtons(false))
    }
  }, [isSmallScreen, dispatch])

  if (isSmallScreen) {
    return <selectComponents.SelectContainer {...props} className={className} />
  }

  const options = arrayWrapSelection(props.getValue())
  const getOptionValue = props.selectProps.getOptionValue
  const getOptionLabel = props.selectProps.getOptionLabel

  const isStickyInNavBar =
    screenWidth >= STICKY_FILTERS_MIN_WIDTH && navScrollPercent === 1

  const maxWidth = isStickyInNavBar
    ? Math.max(380, navCenterWidth)
    : LIMITED_MAX_WIDTH

  if (!getOptionLabel || !getOptionValue) return null

  return (
    <div className={className}>
      <div
        className="filters-list-container"
        style={{width: maxWidth, height: isStickyInNavBar ? 32 : 'auto'}}
        onMouseEnter={() =>
          isStickyInNavBar && dispatch(actions.uiSetHideNavButtons(true))
        }
        onMouseLeave={() =>
          isStickyInNavBar && dispatch(actions.uiSetHideNavButtons(false))
        }
      >
        <div
          className="filters-list"
          style={{
            width: LIMITED_MAX_WIDTH,
            position: isStickyInNavBar ? 'absolute' : 'relative',
          }}
        >
          {options.map(opt => (
            <selectComponents.MultiValue
              {...props}
              cropWithEllipsis={false}
              data={opt.value}
              components={{
                Container: selectComponents.MultiValueContainer,
                Label: selectComponents.MultiValueLabel,
                Remove: selectComponents.MultiValueRemove,
              }}
              key={getOptionValue(opt)}
              removeProps={{
                onClick: () => props.selectOption(opt),
                onTouchEnd: () => props.selectOption(opt),
                onMouseDown: e => {
                  e.preventDefault()
                  e.stopPropagation()
                },
              }}
              isFocused={false}
            >
              <MultiValueLabel data={opt} />
            </selectComponents.MultiValue>
          ))}
        </div>
      </div>
      <selectComponents.SelectContainer {...props} />
    </div>
  )
}
