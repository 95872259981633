import React, {useState, Ref, forwardRef, useEffect} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import Modal, {RenderModalBackdropProps} from 'react-overlays/Modal'
import Card from 'react-bootstrap/Card'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faLockOpen,
  faCircle,
  faRedoAlt,
  faDiceD20,
  faSearch,
  faCog,
} from '@fortawesome/free-solid-svg-icons'
import {faPatreon} from '@fortawesome/free-brands-svg-icons'

import {UserState, RootState, ACCESS_CODE_GRANTS} from '../store/types'
import {patronDescriptionForUser} from '../patreon'
import {externalLinkProps} from './externalLinks'
import {linkPatreonAccount, logoutUser} from '../actions/thunks'

import DetailModeToggle from './DetailModeToggle'
import FreeColumnToggle from './FreeColumnToggle'
import DateFormatToggle from './DateFormatToggle'
import styles from './AccountDetails.module.scss'
import ThemeToggle from './ThemeToggle'
import utilityStyles from './common.module.scss'
import {currentUserCanShareAccess, currentUserMaxShareSlots} from '../selectors'
import {Tooltip} from 'react-bootstrap'
import {ClickOverlayTrigger} from './ClickOverlayTrigger'
import {useIsSmallScreen} from './hooks'
import classnames from 'classnames'

const DISCORD_URL = 'https://discord.gg/BNegdmM'

const _Backdrop = (
  props: RenderModalBackdropProps & {className: string},
  ref: Ref<HTMLDivElement>,
) => {
  return <div {...props} ref={ref} />
}
const Backdrop = forwardRef(_Backdrop)

interface Props {
  show: boolean
  onHide: () => void
  user: UserState | null
  linkPatreonAccount: () => Promise<void>
  logoutUser: () => Promise<void>
  canShareAccess: boolean
  usedShareSlots: number
  maxShareSlots: number
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
  canShareAccess: currentUserCanShareAccess(state),
  maxShareSlots: currentUserMaxShareSlots(state),
  usedShareSlots: state.auth.user?.invitedUserEmails.length || 0,
})

const AccountDetails: React.FC<Props> = ({
  user,
  show,
  onHide,
  canShareAccess,
  usedShareSlots,
  maxShareSlots,
  ...props
}) => {
  const [loadingPatreon, setLoadingPatreon] = useState(false)
  const [isSecondaryModalOpen, setIsSecondaryModalOpen] = useState(false)

  const isSmallScreen = useIsSmallScreen()

  const renderBackdrop = (props: RenderModalBackdropProps) => (
    <Backdrop
      {...props}
      className={classnames(
        styles.backdrop,
        isSecondaryModalOpen && styles['backdrop-open'],
      )}
    />
  )

  useEffect(() => {
    if (isSmallScreen) {
      setIsSecondaryModalOpen(false)
    }
  }, [isSmallScreen])

  return (
    user && (
      <Modal
        show={show}
        onHide={onHide}
        renderBackdrop={renderBackdrop}
        className={styles.modal}
      >
        <div className={styles.card}>
          <div className={styles.header}>
            <div className={styles.email}>{user.email}</div>
            {user.accessCodeGrants?.map(grant => {
              let name: string
              switch (grant) {
                case ACCESS_CODE_GRANTS.TOTAL:
                  name = 'Total Access'
                  break
                case ACCESS_CODE_GRANTS.BOOK_1:
                  name = 'Book 1'
                  break
                case ACCESS_CODE_GRANTS.BOOK_2:
                  name = 'Book 2'
                  break
                default:
                  return
              }

              return (
                <Card.Subtitle key={grant}>
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon fixedWidth icon={faLockOpen} size="xs" />
                  </span>{' '}
                  {name}: Unlocked
                </Card.Subtitle>
              )
            })}
            {user.patreonUserId && (
              <Card.Subtitle>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faPatreon}
                  mask={faCircle}
                  transform="shrink-6"
                />{' '}
                {patronDescriptionForUser(user)}
              </Card.Subtitle>
            )}
          </div>
          <div className={styles.items}>
            <Link to="/update-email" className={styles.menuItem}>
              <span>Change Account Email</span>
              <FontAwesomeIcon icon={faChevronRight} fixedWidth />
            </Link>
            {loadingPatreon ? (
              <div className={styles.menuItem}>
                <span>Loading Patreon...</span>
              </div>
            ) : (
              <a
                href="/"
                className={styles.menuItem}
                onClick={async e => {
                  e.preventDefault()
                  setLoadingPatreon(true)
                  await props.linkPatreonAccount()
                  window.blur()
                }}
              >
                <span>
                  {user.patreonUserId && !user.invitedBy
                    ? 'Refresh Patreon Connection'
                    : 'Link Patreon Account'}
                </span>
                <FontAwesomeIcon
                  icon={user.patreonUserId ? faRedoAlt : faChevronRight}
                  fixedWidth
                />
              </a>
            )}
            <Link to="/apply-code" className={styles.menuItem}>
              <span>Apply Access Code</span>
              <FontAwesomeIcon icon={faChevronRight} fixedWidth />
            </Link>

            {canShareAccess && (
              <Link to="/share-access" className={styles.menuItem}>
                <span>
                  Share Ledger+ Access{' '}
                  <span style={{fontWeight: 'normal'}}>
                    ({maxShareSlots - usedShareSlots}/{maxShareSlots} remaining)
                  </span>
                </span>
                <FontAwesomeIcon icon={faChevronRight} fixedWidth />
              </Link>
            )}
            <a
              className={styles.menuItem}
              href={DISCORD_URL}
              {...externalLinkProps}
            >
              <span>Get Help on Discord</span>
              <img
                src="/images/icon-arrow-external.png"
                className={utilityStyles.lightOnly}
                style={{width: 16, marginLeft: 5, marginBottom: 5}}
                alt=""
              />
              <img
                src="/images/icon-arrow-external-dark.png"
                className={utilityStyles.darkOnly}
                style={{width: 16, marginLeft: 5, marginBottom: 5}}
                alt=""
              />
            </a>
            <a
              className={styles.menuItem}
              href="mailto:thegriffonssaddlebag@gmail.com"
            >
              <span>Email Support</span>
              <img
                src="/images/icon-arrow-external.png"
                className={utilityStyles.lightOnly}
                style={{width: 16, marginLeft: 5, marginBottom: 5}}
                alt=""
              />
              <img
                src="/images/icon-arrow-external-dark.png"
                className={utilityStyles.darkOnly}
                style={{width: 16, marginLeft: 5, marginBottom: 5}}
                alt=""
              />
            </a>
            {!isSmallScreen && (
              <button
                className={styles.menuItem}
                onClick={() => setIsSecondaryModalOpen(prev => !prev)}
              >
                <span>Preferences</span>
                <FontAwesomeIcon icon={faCog} fixedWidth />
              </button>
            )}
            <a
              href="/"
              className={styles.menuItem}
              onClick={async e => {
                e.preventDefault()
                await props.logoutUser()
              }}
            >
              <span>Log Out</span>
              <FontAwesomeIcon icon={faChevronRight} fixedWidth />
            </a>
            {!isSmallScreen && (
              <div className={styles.tooltipMenuItemsContainer}>
                <ClickOverlayTrigger
                  overlay={
                    <Tooltip id="generate-loot-table">
                      Click the die icon on the upper left
                    </Tooltip>
                  }
                  placement="left"
                >
                  <div className={styles.tooltipMenuItem}>
                    <span>Generate loot table</span>
                    <FontAwesomeIcon icon={faDiceD20} fixedWidth />
                  </div>
                </ClickOverlayTrigger>
                <ClickOverlayTrigger
                  overlay={
                    <Tooltip id="search-by-description">
                      Press TAB when typing a search term
                    </Tooltip>
                  }
                  placement="left"
                >
                  <div className={styles.tooltipMenuItem}>
                    <span>Search by description</span>
                    <FontAwesomeIcon icon={faSearch} fixedWidth />
                  </div>
                </ClickOverlayTrigger>
              </div>
            )}
          </div>
          <div
            className={isSecondaryModalOpen ? styles['secondary-modal'] : ''}
          >
            {(isSmallScreen || isSecondaryModalOpen) && <AccountPreferences />}
          </div>
        </div>
      </Modal>
    )
  )
}

function AccountPreferences() {
  return (
    <>
      <div className={styles.toggleContainer}>
        <div className={styles.toggleLabel}>Display theme</div>
        <ThemeToggle />
      </div>
      <div className={styles.toggleContainer}>
        <div className={styles.toggleLabel}>Item display mode</div>
        <DetailModeToggle />
      </div>
      <div className={styles.toggleContainer}>
        <div className={styles.toggleLabel}>Free column</div>
        <FreeColumnToggle />
      </div>
      <div className={styles.toggleContainer}>
        <div className={styles.toggleLabel}>Date format</div>
        <DateFormatToggle />
      </div>
    </>
  )
}

export default connect(mapStateToProps, {linkPatreonAccount, logoutUser})(
  AccountDetails,
)
