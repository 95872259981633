import React from 'react'

import Form from 'react-bootstrap/Form'

import {motion} from 'framer-motion'

export function EmailPasswordInput({
  email,
  setEmail,
  password,
  setPassword,
  showPasswordInput,
}: {
  email: string
  setEmail: (email: string) => void
  password: string
  setPassword: (password: string) => void
  showPasswordInput: boolean
}) {
  return (
    <>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          value={email}
          onChange={({target}) => setEmail(target.value || '')}
        />
      </Form.Group>
      <motion.div
        initial={false}
        style={{
          overflow: 'hidden',
          // Negative margin and padding to prevent input outline from getting cut off
          margin: '0px -4px',
          padding: '0px 4px',
        }}
        animate={{maxHeight: showPasswordInput ? '150px' : '0px'}}
      >
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={({target}) => setPassword(target.value || '')}
          />
        </Form.Group>
      </motion.div>
    </>
  )
}
