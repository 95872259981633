import {createReducer} from 'typesafe-actions'
import {actions} from '../actions/standard'
import * as types from '../store/types'

import listReducer, {initialState as initialListViewState} from './list'

const initialState: types.RootState = {
  totalItemCount: 0,
  contents: null,
  selectedItemId: null,
  selectedBundleId: null,
  ui: {
    listMode: 'list',
    detailMode: 'card',
    dateFormat: 'short',
    showPaid: false,
    savingItem: false,
    navExpanded: false,
    navExtraVerticalSpace: 0,
    accountShowing: false,
    freeColumnVisible: false,
    navCenterWidth: 0,
    navScrollPercent: 0,
    hideNavButtons: false,
  },
  auth: {
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: true,
    loginError: false,
    logoutError: false,
    isAuthenticated: false,
    user: null,
    isSigningUp: false,
    signUpErrorMessage: null,
    isLoggingInWithPassword: false,
    loginWithPasswordErrorMessage: null,
  },
  myBundles: {},
  listViews: {
    home: {...initialListViewState, identifier: 'home'},
    stash: {...initialListViewState, identifier: 'stash'},
    stashAdd: {...initialListViewState, identifier: 'stashAdd'},
  },
}

export default createReducer(initialState)
  // List reducer
  .handleAction(
    [
      actions.updateFilterState,
      actions.uiToggleSortColumn,
      actions.uiSetVisibleSecondaryColumn,
      actions.uiSetDateColumnMode,
    ],
    (state, action) => ({
      ...state,
      listViews: {
        home: listReducer(state.listViews.home, action),
        stash: listReducer(state.listViews.stash, action),
        stashAdd: listReducer(state.listViews.stashAdd, action),
      },
    }),
  )
  // Root reducer
  .handleAction(actions.setTotalItemCount, (state, action) => ({
    ...state,
    totalItemCount: action.payload,
  }))
  .handleAction(actions.setSaddlebagContents, (state, action) => ({
    ...state,
    contents: action.payload,
  }))
  .handleAction(actions.addSaddlebagItem, (state, {payload}) => ({
    ...state,
    contents: [...(state.contents || []), payload],
  }))
  .handleAction(actions.updateSaddlebagItem, (state, {payload: item}) => {
    const contents =
      state.contents?.map(i => {
        if (i.id === item.id) {
          return item
        }
        return i
      }) || null
    return {...state, contents}
  })
  .handleAction(actions.removeItem, (state, {payload: item}) => {
    const contents =
      state.contents?.filter(i => (i.id === item.id ? false : true)) || null
    return {...state, contents}
  })
  .handleAction(actions.setSelectedItem, (state, {payload: id}) => ({
    ...state,
    selectedItemId: id,
  }))
  .handleAction(actions.uiSetListMode, (state, action) => ({
    ...state,
    ui: {...state.ui, listMode: action.payload},
  }))
  .handleAction(actions.uiSetShowPaid, (state, action) => ({
    ...state,
    ui: {...state.ui, showPaid: action.payload},
  }))
  .handleAction(actions.uiSetNavExpanded, (state, action) => ({
    ...state,
    ui: {...state.ui, navExpanded: action.payload},
  }))
  .handleAction(actions.uiSetNavExtraVerticalSpace, (state, action) => ({
    ...state,
    ui: {...state.ui, navExtraVerticalSpace: action.payload},
  }))
  .handleAction(actions.uiSetNavCenterWidth, (state, action) => ({
    ...state,
    ui: {...state.ui, navCenterWidth: action.payload},
  }))
  .handleAction(actions.uiSetNavScrollPercent, (state, action) => ({
    ...state,
    ui: {...state.ui, navScrollPercent: action.payload},
  }))
  .handleAction(actions.uiSetHideNavButtons, (state, action) => ({
    ...state,
    ui: {...state.ui, hideNavButtons: action.payload},
  }))
  .handleAction(actions.uiSetAccountShowing, (state, action) => ({
    ...state,
    ui: {...state.ui, accountShowing: action.payload},
  }))
  .handleAction(actions.uiSetDetailMode, (state, action) => ({
    ...state,
    ui: {...state.ui, detailMode: action.payload},
  }))
  .handleAction(actions.uiSetDateFormat, (state, action) => ({
    ...state,
    ui: {...state.ui, dateFormat: action.payload},
  }))
  .handleAction(actions.uiSetFreeColumnVisible, (state, action) => ({
    ...state,
    ui: {...state.ui, freeColumnVisible: action.payload},
  }))

  // AUTH
  .handleAction(actions.requestLogin, state => ({
    ...state,
    auth: {...state.auth, isLoggingIn: true, loginError: false},
  }))
  .handleAction(actions.authSucceeded, (state, {payload}) => ({
    ...state,
    auth: {
      ...state.auth,
      isAuthenticated: true,
      isLoggingIn: false,
      isSigningUp: false,
      isLoggingInWithPassword: false,
      user: payload,
    },
  }))
  .handleAction(actions.loginFailed, state => ({
    ...state,
    auth: {...state.auth, isLoggingIn: false, loginError: true, user: null},
  }))
  .handleAction(actions.requestSignUp, state => ({
    ...state,
    auth: {...state.auth, isSigningUp: true, signUpErrorMessage: null},
  }))
  .handleAction(actions.signUpFailed, (state, {payload}) => ({
    ...state,
    auth: {
      ...state.auth,
      isSigningUp: false,
      signUpErrorMessage: payload,
      user: null,
    },
  }))
  .handleAction(actions.setEmailVerified, (state, {payload}) => ({
    ...state,
    auth: {
      ...state.auth,
      ...(state.auth.user && {
        user: {...state.auth.user, emailVerified: payload},
      }),
    },
  }))
  .handleAction(actions.requestLoginWithPassword, state => ({
    ...state,
    auth: {
      ...state.auth,
      isLoggingInWithPassword: true,
      loginWithPasswordErrorMessage: null,
    },
  }))
  .handleAction(actions.loginWithPasswordFailed, (state, {payload}) => ({
    ...state,
    auth: {
      ...state.auth,
      isLoggingInWithPassword: false,
      loginWithPasswordErrorMessage: payload,
      user: null,
    },
  }))
  .handleAction(actions.requestLogout, state => ({
    ...state,
    auth: {...state.auth, isLoggingOut: true, logoutError: false},
  }))
  .handleAction(actions.logoutSucceeded, state => ({
    ...state,
    auth: {
      ...state.auth,
      isAuthenticated: false,
      isLoggingOut: false,
      user: null,
    },
  }))
  .handleAction(actions.logoutFailed, state => ({
    ...state,
    auth: {...state.auth, isLoggingOut: false, logoutError: true},
  }))
  .handleAction(actions.requestVerify, state => ({
    ...state,
    auth: {...state.auth, isVerifying: true},
  }))
  .handleAction(actions.verifySucceeded, state => ({
    ...state,
    auth: {...state.auth, isVerifying: false},
  }))
  .handleAction(actions.setUserEmail, (state, {payload}) => {
    if (!state.auth.user) return state
    return {
      ...state,
      auth: {
        ...state.auth,
        user: {
          ...state.auth.user,
          email: payload,
        },
      },
    }
  })
  .handleAction(actions.savingItem, (state, {payload}) => ({
    ...state,
    ui: {...state.ui, savingItem: payload},
  }))
  // Bundles
  .handleAction(actions.setMyBundles, (state, {payload: bundles}) => {
    const myBundles: typeof state['myBundles'] = {}
    for (const b of bundles) {
      if (!myBundles[b.id]) myBundles[b.id] = b
    }
    return {
      ...state,
      myBundles,
    }
  })
  .handleAction(actions.setSelectedBundle, (state, {payload: bundleId}) => ({
    ...state,
    selectedBundleId: bundleId,
  }))
  .handleAction(
    actions.setBundleName,
    (state, {payload: {bundleId, name}}) => ({
      ...state,
      myBundles: {
        ...state.myBundles,
        [bundleId]: {...state.myBundles[bundleId], name},
      },
    }),
  )
  .handleAction(
    actions.setBundleItems,
    (state, {payload: {bundleId, items}}) => ({
      ...state,
      myBundles: {
        ...state.myBundles,
        [bundleId]: {...state.myBundles[bundleId], items},
      },
    }),
  )
  .handleAction(
    actions.addItemToBundle,
    (state, {payload: {bundleId, itemId}}) => {
      return {
        ...state,
        myBundles: {
          ...state.myBundles,
          [bundleId]: {
            ...state.myBundles[bundleId],
            items: [...state.myBundles[bundleId].items, itemId],
          },
        },
      }
    },
  )
  .handleAction(
    actions.removeItemFromBundle,
    (state, {payload: {bundleId, itemId}}) => {
      return {
        ...state,
        myBundles: {
          ...state.myBundles,
          [bundleId]: {
            ...state.myBundles[bundleId],
            items: state.myBundles[bundleId].items.filter(i => i !== itemId),
          },
        },
      }
    },
  )
  .handleAction(actions.addBundle, (state, {payload: bundle}) => {
    return {
      ...state,
      myBundles: {
        ...state.myBundles,
        [bundle.id]: bundle,
      },
    }
  })
  .handleAction(actions.removeBundle, (state, {payload: bundle}) => {
    const myBundles = {...state.myBundles}
    delete myBundles[bundle.id]
    return {
      ...state,
      myBundles,
    }
  })
  .handleAction(actions.setInvitedUserEmails, (state, {payload}) => {
    if (!state.auth.user) return state
    return {
      ...state,
      auth: {
        ...state.auth,
        user: {
          ...state.auth.user,
          invitedUserEmails: payload,
        },
      },
    }
  })
