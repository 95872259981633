import {
  ItemFilterState,
  UserState,
  SortColumn,
  ItemBundle,
  ListIdentifier,
  DetailMode,
  DateFormat,
  GriffonDatabaseItem,
  DateColumnMode,
} from '../store/types'
import {createStandardAction, createAction} from 'typesafe-actions'

export const actions = {
  // per-list actions
  updateFilterState: createAction(
    'UPDATE_FILTER_STATE',
    action => (payload: Partial<ItemFilterState>, listName: ListIdentifier) =>
      action(payload, {listName}),
  ),
  setSelectedItem: createAction(
    'SET_SELECTED_ITEM',
    action => (payload: string, listName: ListIdentifier) =>
      action(payload, {listName}),
  ),
  uiSetListMode: createAction(
    'UI.SET_LIST_MODE',
    action => (payload: 'list' | 'grid', listName: ListIdentifier) =>
      action(payload, {listName}),
  ),
  uiToggleSortColumn: createAction(
    'UI.TOGGLE_SORT_COLUMN',
    action => (payload: SortColumn, listName: ListIdentifier) =>
      action(payload, {listName}),
  ),
  uiSetVisibleSecondaryColumn: createAction(
    'UI.SET_LIST_VISIBLE_SECONDARY_COLUMN',
    action => (
      payload: Exclude<SortColumn, 'name'>,
      listName: ListIdentifier,
    ) => action(payload, {listName}),
  ),
  uiSetDateColumnMode: createAction(
    'UI.SET_DATE_COLUMN_MODE',
    action => (payload: DateColumnMode, listName: ListIdentifier) =>
      action(payload, {listName}),
  ),

  // saddlebag contents
  setTotalItemCount: createStandardAction('SET_TOTAL_ITEM_COUNT')<number>(),
  setSaddlebagContents: createStandardAction('SET_SADDLEBAG_CONTENTS')<
    GriffonDatabaseItem[]
  >(),
  addSaddlebagItem: createStandardAction('ADD_SADDLEBAG_ITEM')<
    GriffonDatabaseItem
  >(),
  updateSaddlebagItem: createStandardAction('UPDATE_SADDLEBAG_ITEM')<
    GriffonDatabaseItem
  >(),
  removeItem: createStandardAction('REMOVE_ITEM')<GriffonDatabaseItem>(),

  uiSetShowPaid: createStandardAction('UI.SET_SHOW_PAID')<boolean>(),
  uiSetNavExpanded: createStandardAction('UI.SET_NAV_EXPANDED')<boolean>(),
  uiSetNavExtraVerticalSpace: createStandardAction(
    'UI.SET_NAV_EXTRA_VERTICAL_SPACE',
  )<number>(),
  uiSetAccountShowing: createStandardAction('UI.SET_ACCOUNT_SHOWING')<
    boolean
  >(),
  uiSetDetailMode: createStandardAction('UI.SET_DETAIL_MODE')<DetailMode>(),
  uiSetDateFormat: createStandardAction('UI.SET_DATE_FORMAT')<DateFormat>(),
  uiSetFreeColumnVisible: createStandardAction('UI.SET_FREE_COLUMN_VISIBLE')<
    boolean
  >(),
  uiSetNavCenterWidth: createStandardAction('UI.SET_NAV_CENTER_WIDTH')<
    number
  >(),
  uiSetNavScrollPercent: createStandardAction('UI.SET_NAV_SCROLL_PERCENT')<
    number
  >(),
  uiSetHideNavButtons: createStandardAction('UI.SET_HIDE_NAV_BUTTONS')<
    boolean
  >(),

  requestLogin: createStandardAction('LOGIN.REQUEST')(),
  authSucceeded: createStandardAction('AUTH.SUCCESS')<UserState>(),
  loginFailed: createStandardAction('LOGIN.FAILURE')(),

  requestLogout: createStandardAction('LOGOUT.REQUEST')(),
  logoutSucceeded: createStandardAction('LOGOUT.SUCCESS')(),
  logoutFailed: createStandardAction('LOGOUT.FAILURE')(),

  requestSignUp: createStandardAction('SIGNUP.REQUEST')(),
  signUpFailed: createStandardAction('SIGNUP.FAILURE')<string>(),

  setEmailVerified: createStandardAction('AUTH.SET_EMAIL_VERIFIED')<boolean>(),

  requestLoginWithPassword: createStandardAction(
    'LOGIN_WITH_PASSWORD.REQUEST',
  )(),
  loginWithPasswordFailed: createStandardAction('LOGIN_WITH_PASSWORD.FAILURE')<
    string
  >(),

  requestVerify: createStandardAction('AUTH_VERIFY.REQUEST')(),
  verifySucceeded: createStandardAction('AUTH_VERIFY.SUCCESS')(),
  setUserEmail: createStandardAction('AUTH.SET_USER_EMAIL')<string>(),

  savingItem: createStandardAction('UI.SET_SAVING_ITEM')<boolean>(),

  addBundle: createStandardAction('BUNDLE.ADD_BUNDLE')<ItemBundle>(),
  removeBundle: createStandardAction('BUNDLE.REMOVE_BUNDLE')<ItemBundle>(),
  setMyBundles: createStandardAction('BUNDLE.SET_MY_BUNDLES')<ItemBundle[]>(),
  setSelectedBundle: createStandardAction('BUNDLE.SET_SELECTED_BUNDLE')<
    string
  >(),
  setBundleName: createStandardAction('BUNDLE.SET_NAME')<{
    bundleId: string
    name: string
  }>(),
  setBundleItems: createStandardAction('BUNDLE.SET_ITEMS')<{
    bundleId: string
    items: string[]
  }>(),
  addItemToBundle: createStandardAction('BUNDLE.ADD_ITEM')<{
    bundleId: string
    itemId: string
  }>(),
  removeItemFromBundle: createStandardAction('BUNDLE.REMOVE_ITEM')<{
    bundleId: string
    itemId: string
  }>(),
  setInvitedUserEmails: createStandardAction('SET_INVITED_USER_EMAILS')<
    string[]
  >(),
}

export type AllActions = ReturnType<typeof actions[keyof typeof actions]>
