import React, {FormEvent, ReactNode, useState} from 'react'
import Layout from './Layout'

import {
  Alert,
  Button,
  Container,
  FormControl,
  FormLabel,
  Modal,
} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDiceD20} from '@fortawesome/free-solid-svg-icons'
import {getFunctions, httpsCallable} from 'firebase/functions'
import {myFirebase} from '../firebase/firebase'
import {useDispatch, useSelector} from 'react-redux'
import {RootState, StoreDispatch} from '../store'
import {Redirect} from 'react-router-dom'
import {getAuth} from 'firebase/auth'
import {actions} from '../actions'

const auth = getAuth(myFirebase)

export default function UpdateEmail() {
  const email = useSelector((state: RootState) => state.auth.user?.email)

  const [updatedEmail, setUpdatedEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [alert, setAlert] = useState<{text: ReactNode; variant: string} | null>(
    null,
  )

  const buttonDisabled = !updatedEmail || isSubmitting || email === updatedEmail
  const dispatch = useDispatch<StoreDispatch>()

  const updateEmail = httpsCallable(getFunctions(myFirebase), 'updateUserEmail')

  const submit = async (e: FormEvent) => {
    e.preventDefault()
    if (isSubmitting) return
    setAlert(null)
    setIsSubmitting(true)
    try {
      if (!auth.currentUser) {
        throw new Error('No user found')
      }
      await updateEmail(updatedEmail.trim())
      await auth.currentUser.reload()
      dispatch(actions.setUserEmail(updatedEmail))
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err: any = e
      setIsSubmitting(false)
      setAlert({text: err.message, variant: 'danger'})
      console.error(err)
      return
    }
    setAlert({
      text: (
        <>
          Successfully updated email to <strong>{updatedEmail.trim()}</strong>
        </>
      ),
      variant: 'success',
    })
    setIsSubmitting(false)
  }

  if (!email) {
    return <Redirect to="/" />
  }

  return (
    <Layout>
      <Alert
        dismissible
        variant={alert?.variant}
        show={Boolean(alert)}
        transition
      >
        {alert?.text}
      </Alert>
      <form onSubmit={submit}>
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Update email </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container
              style={{gap: '1rem', display: 'flex', flexDirection: 'column'}}
            >
              <div>
                <FormLabel>Current email</FormLabel>
                <FormControl
                  placeholder="Current email"
                  type="text"
                  value={email}
                  disabled
                />
              </div>
              <div>
                <FormLabel>New email</FormLabel>
                <FormControl
                  placeholder="New email"
                  type="text"
                  value={updatedEmail}
                  onChange={e => setUpdatedEmail(e.target.value)}
                />
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <p style={{fontSize: 14}} className="w-100 text-muted">
              ⚠️ Updating your email will log you out. You&apos;ll need to log
              in again using your new email address.
            </p>
            <Button type="submit" disabled={buttonDisabled}>
              {isSubmitting ? (
                <>
                  <FontAwesomeIcon icon={faDiceD20} spin /> Loading&hellip;
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </form>
    </Layout>
  )
}
