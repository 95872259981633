import {useEffect, useState} from 'react'

const SMALL_SCREEN_WIDTH = 992

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return screenSize
}

export const useIsSmallScreen = () => {
  const {width} = useScreenSize()

  return width < SMALL_SCREEN_WIDTH
}
