import {components} from 'react-select'
import {SelectComponents} from 'react-select/src/components'
import {FiltersOptionType} from './types'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'
import {useIsSmallScreen} from '../hooks'
import {PlaceholderText} from './Placeholder'

export const ValueContainer: SelectComponents<
  FiltersOptionType
>['ValueContainer'] = ({children, ...props}) => {
  const isSmallScreen = useIsSmallScreen()
  if (isSmallScreen) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    )
  }
  const newChildren = React.Children.map(children, child => {
    if (
      child &&
      typeof child === 'object' &&
      'type' in child &&
      child.type === components.MultiValue
    ) {
      return null
    }

    return child
  })

  const inputText = React.Children.toArray(newChildren).reduce(
    (_, child) =>
      typeof child === 'object' &&
      'type' in child &&
      child.type === components.Input &&
      'value' in child.props
        ? child.props.value
        : null,
    null,
  )

  return (
    <components.ValueContainer {...props}>
      <FontAwesomeIcon
        icon={faSearch}
        fixedWidth
        className="text-primary d-none d-lg-inline-block"
      />

      <div
        className="position-relative ml-2 d-flex align-items-center"
        style={{flex: 1}}
      >
        {!inputText && (
          <div className="text-muted font-italic position-absolute">
            <PlaceholderText>{props.selectProps.placeholder}</PlaceholderText>
          </div>
        )}
        {newChildren}
      </div>
    </components.ValueContainer>
  )
}
