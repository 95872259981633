import React, {useState, FormEventHandler} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

import Modal from 'react-bootstrap/Modal'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

import {myFirebase} from '../firebase/firebase'

import Layout from './Layout'
import {Alert, FormLabel} from 'react-bootstrap'
import {RootState} from '../store/types'

import {getAuth, sendPasswordResetEmail} from 'firebase/auth'

interface Props {
  isAuthenticated: boolean
}

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
})
const auth = getAuth(myFirebase)

const ResetPassword: React.FC<Props> = ({isAuthenticated}) => {
  const [email, setEmail] = useState('')
  const [alert, setAlert] = useState<{text: string; variant: string} | null>(
    null,
  )
  const submit: FormEventHandler = e => {
    e.preventDefault()
    try {
      setAlert(null)
      sendPasswordResetEmail(auth, email, {
        handleCodeInApp: false,
        url: `https://${window.location.host}`,
      })
      setAlert({
        text: 'Email sent! Please check your inbox.',
        variant: 'success',
      })
    } catch (e) {
      setAlert({
        text: 'Something went wrong. Please try again.',
        variant: 'danger',
      })
    }
  }
  if (isAuthenticated) {
    return <Redirect to="/" />
  }
  return (
    <Layout>
      <Alert variant={alert?.variant} show={Boolean(alert)} transition>
        {alert?.text}
      </Alert>
      <form onSubmit={submit}>
        <Modal.Dialog size="lg">
          <Modal.Header>
            <Modal.Title>
              Reset password
              <Modal.Title as="h6" className="text-muted">
                Enter your email to reset your password
              </Modal.Title>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormLabel>Email</FormLabel>
            <FormControl
              placeholder="Email"
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              disabled={!email || alert?.variant === 'success'}
            >
              Send email
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </form>
    </Layout>
  )
}

export default connect(mapStateToProps)(ResetPassword)
